// MAIN.JS
jQuery(function($) {


	/**********************************************/
	$layerslider = $('#layerslider');
	$layerslidermobile = $('#layerslider-mobile');

	$layerslider.layerSlider({
		responsive: true,
		pauseOnHover: false,
		navPrevNext: true,
		navStartStop: false,
		hoverPrevNext: true,
		navButtons: true,
		keybNav: true,
		showCircleTimer: false,
		thumbnailNavigation: 'disabled',
		skinsPath: 'css/layerslider/skins/',
		autoStart: true
	});

	$layerslidermobile.layerSlider({
		responsiveUnder: 480,
		hideOver: 480,
		layersContainer : 480,
		navButtons: false,
		pauseOnHover: false,
		navPrevNext: false,
		navStartStop: false,
		hoverPrevNext: false,
		keybNav: false,
		showCircleTimer: false,
		thumbnailNavigation: 'disabled',
		skinsPath: 'css/layerslider/skins/',
	});

	$(".slideshow-next").click(function() {
		$layerslider.layerSlider('next');
		$layerslidermobile.layerSlider('next');
	});
	$(".slideshow-prev").click(function() {
		$layerslider.layerSlider('prev');
		$layerslidermobile.layerSlider('prev');
	});

	/**********************************************/

	// MENU
	var menu = ".menu-mobile";
	var menuToggle = ".menu-toggle";
	$(menuToggle).click(function(e) {
		$(menu).toggleClass("toggled");
		$(menuToggle).toggleClass("toggled");
	});

	/**********************************************/

	// JQUERY.MATCHHEIGHT
	if ($.fn.matchHeight) {
		$(".matchHeight").matchHeight();
	}



	/**********************************************/
	// PARALLAX
	// if ($.fn.parallax) {
	// 	$(".parallax-slow").parallax({
	// 		speed: 0.1
	// 	});
	// 	$(".parallax").parallax({
	// 		speed: 0.3
	// 	});
	// 	$(".parallax-fast").parallax({
	// 		speed: 0.5
	// 	});
	// }

	// PARALLAX
	// function parallax() {
	// 	var scroll = $(window).scrollTop();
	// 	// alert(scroll);
	//
	// 	if (scroll < 700) {
	// 		translation = "translateX(-100vw)";
	// 		$(".parallax-left-1").css({
	// 			transform: translation,
	// 		});
	// 	}
	// 	if (scroll > 700) {
	// 		translation = "translateX(0vw)";
	// 		$(".parallax-left-1").css({
	// 			transform: translation,
	// 		});
	// 	}
	// 	if (scroll < 900) {
	// 		translation = "translateX(-100vw)";
	// 		$(".parallax-left-2").css({
	// 			transform: translation,
	// 		});
	// 	}
	// 	if (scroll > 900) {
	// 		translation = "translateX(0vw)";
	// 		$(".parallax-left-2").css({
	// 			transform: translation,
	// 		});
	// 	}
	// 	if (scroll < 1100) {
	// 		translation = "translateX(-100vw)";
	// 		$(".parallax-left-3").css({
	// 			transform: translation,
	// 		});
	// 	}
	// 	if (scroll > 1100) {
	// 		translation = "translateX(0vw)";
	// 		$(".parallax-left-3").css({
	// 			transform: translation,
	// 		});
	// 	}
	// }
	// parallax();
	// $( window ).scroll(function() {
	// 	parallax();
	// });
	// $( window ).on('load',function() {
	// 	parallax();
	// });

	/**********************************************/
	/* menu hide and show when scrolled
	/**********************************************/
	var didScroll;
	var lastScrollTop = 0;
	var delta = 5;
	var navbarHeight = $(".menu-desktop").outerHeight();

	$(window).scroll( function(event) {
		didScroll = true;
	});
	setInterval(function() {
		if (didScroll) {
			hasScrolled();
			didScroll = false;
		}
	}, 250);

	function hasScrolled() {
		var st = $(this).scrollTop();

		// Make sure they scroll more than delta
	    if(Math.abs(lastScrollTop - st) <= delta)
	        return;
	    // If they scrolled down and are past the navbar, add class .nav-up.
	    // This is necessary so you never see what is "behind" the navbar.
	    if (st > lastScrollTop && st > navbarHeight){
	        // Scroll Down
	        $('.menu-desktop').removeClass('nav-down').addClass('nav-up');
	    } else {
	        // Scroll Up
	        if(st + $(window).height() < $(document).height()) {
	            $('.menu-desktop').removeClass('nav-up').addClass('nav-down');
			}
		}
		lastScrollTop = st;

		if (st > 0) {
			$('.module-menu').addClass('scrolled');
		}
		else {
			$('.module-menu').removeClass('scrolled');
		}
	}

});